/* @component id="screen-size-blocker" version="1.0.0" */
"use client";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import React, { useEffect, useState } from "react";

export default function ScreenSizeBlocker() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the size threshold as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!isSmallScreen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
      <div className="absolute inset-0 backdrop-blur-md"></div>
      <div className="relative z-10 flex flex-col w-[80%] bg-white px-8 py-10 rounded-lg text-center">
        <div className="flex flex-row justify-center text-center pb-2">
          <InfoCircledIcon className="mr-2" height={24} width={24} />
          <h2 className="text-xl font-semibold mb-2">
            Screen Size Not Supported
          </h2>
        </div>
        <p>
          This application is not optimized for small screens. Please use a
          larger screen / computer for the best experience.
        </p>
        <p className="pt-4">
          If you do not have access to your computer, you may reach out to{" "}
          <Link
            className="text-pink-600 underline underline-offset-2"
            href="mailto:support@gazl.co"
          >
            Gazelle Support
          </Link>{" "}
          by email.
        </p>
      </div>
    </div>
  );
}
